import * as React from 'react'

const SingleIngredient = ({ data }) => {
  return (
    <div className="section--ingredients__ingredient">
        <p className="text-default">
            {data.amount} {data.ingredient} <span className="italic">{data.notes}</span>
            
        </p>
    </div>
  )
}

export default SingleIngredient