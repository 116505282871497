import * as React from 'react'
import SingleIngredient from './ingredient'

const IngredientsList = ({ ingredientList }) => {
  return (
    <div id="ingredients" className="section--ingredients items-center justify-center">
        {
          ingredientList.map((node, y) => (
            <SingleIngredient key={y} data={node} />
          ))
        }
    </div>
  )
}

export default IngredientsList