import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import IngredientsList from '../../components/food-components/ingredients'
import parse from 'html-react-parser'


const FoodPost = ({data}) => {
    return (
      <Layout data={data.wpFood} >
        <main className="recipe mx-auto">
          <section className="recipe__introduction items-center py-20 justify-center bg-red-600 text-white">
            <div className="container px-8 py-8 md:w-2/3 lg:w-1/2 mx-auto ">
              <h2 className="text-white text-center">{data.wpFood.defaults.food.introduction.title}</h2>
              <div class="introduction--content text-center text-[24px]">{parse(data.wpFood.defaults.food.introduction.content)}</div>
            </div>
          </section>
          <section className="recipe__instructions py-20 lg:px-20">
            <div className="container mx-auto flex flex-wrap px-4">
              <div className="recipe__instructions--ingredients mx-10 pb-5 lg:pr-20 lg:mx-0 w-full lg:w-4/12 xl:w-3/12 text-center lg:text-left border-b-2 border-red-600 lg:border-b-0 lg:border-r-2">
                <h2 className="text-red-600 text-2xl mb-5">Ingredients:</h2>
                <IngredientsList ingredientList={data.wpFood.defaults.food.ingredients} />
              </div>
              <div className="recipe__instructions--instruction w-full px-20 lg:px-0 pt-20 lg:pl-20 lg:pt-0 lg:w-8/12 xl:w-9/12">
                <h2 className="text-red-600 text-2xl mb-5 text-center lg:text-left">Instructions:</h2>
                {
                  data.wpFood.defaults.sectionPack.map(node => (
                    <div className="py-10">
                      <h3 className="font-serif text-red-600 text-xl">{node.sectionFields.title}</h3>
                      <div>{parse(node.sectionFields.content)}</div>
                    </div>
                  ))
                }
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
}

export const query = graphql`
    query ($slug: String) {
        wpFood(slug: {eq: $slug}) {
            id
            title
            nodeType
            defaults {
              subtitle
              food {
                ingredients {
                  amount
                  ingredient
                  notes
                }
                introduction {
                  title
                  subtitle
                  content
                }
              }
              sectionPack {
                sectionName
                columnCount
                sectionFields {
                  content
                  title
                  subtitle
                  image {
                    gatsbyImage(width: 1000)
                  }
                }
              }
            }
            seo {
              metaDesc
              opengraphUrl
              opengraphType
              opengraphTitle
              opengraphSiteName
              title
            }
            featuredImage {
              node {
                gatsbyImage(width: 1000)
                localFile {
                  childImageSharp{
                    gatsbyImageData(width:200)
                  }
                }
              }
            }
        }
    }
`

export default FoodPost